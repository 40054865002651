import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const ReceiptHeadList = SuspenseWrapper(
  lazy(() => import("components/fee/ReceiptHeadList"))
);
const ReceiptHeadForm = SuspenseWrapper(
  lazy(() => import("components/fee/ReceiptHeadForm"))
);
const AccountList = SuspenseWrapper(
  lazy(() => import("components/fee/AccountList"))
);
const AccountForm = SuspenseWrapper(
  lazy(() => import("components/fee/AccountForm"))
);
const PaymentModeList = SuspenseWrapper(
  lazy(() => import("components/fee/PaymentModeList"))
);
const PaymentModeForm = SuspenseWrapper(
  lazy(() => import("components/fee/PaymentModeForm"))
);
const FeeTermsList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeTermsList"))
);
const FeeTermsForm = SuspenseWrapper(
  lazy(() => import("components/fee/FeeTermsForm"))
);
const FeeCategoryList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeCategoryList"))
);
const FeeCategoryForm = SuspenseWrapper(
  lazy(() => import("components/fee/FeeCategoryForm"))
);
const FeeItemList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeItemList"))
);
const FeeItemForm = SuspenseWrapper(
  lazy(() => import("components/fee/FeeItemForm"))
);
const FeeBankList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeBankList"))
);
const FeeBankForm = SuspenseWrapper(
  lazy(() => import("components/fee/FeeBankForm"))
);
const FeeStructureList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeStructureList"))
);
const FeeStructureStep = SuspenseWrapper(
  lazy(() => import("components/fee/FeeStructureStep"))
);
const FeeStructureView = SuspenseWrapper(
  lazy(() => import("components/fee/FeeStructureView"))
);
const FeeCollectionForm = SuspenseWrapper(
  lazy(() => import("components/fee/FeeCollectionForm"))
);
const FeeCollectionList = SuspenseWrapper(
  lazy(() => import("components/fee/reports/CollectionList"))
);
const FeeReceiptList = SuspenseWrapper(
  lazy(() => import("components/fee/reports/ReceiptList"))
);
const FeeConcessionList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeConcessionList"))
);
const FeeConcessionStep = SuspenseWrapper(
  lazy(() => import("components/fee/FeeConcessionStep"))
);
const FeeConcessionView = SuspenseWrapper(
  lazy(() => import("components/fee/FeeConcessionView"))
);
const FeeConcessionScholarList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeConcessionScholarList"))
);
const FeeStructureScholarList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeStructureScholarList"))
);
const FeeReceiptCancelList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeReceiptCancelList"))
);
const FeeReceiptCancelForm = SuspenseWrapper(
  lazy(() => import("components/fee/FeeReceiptCancelForm"))
);
const FRCancelReasonForm = SuspenseWrapper(
  lazy(() => import("components/fee/FRCancelReasonForm"))
);
const FRCancelReasonList = SuspenseWrapper(
  lazy(() => import("components/fee/FRCancelReasonList"))
);
const FeeRefundForm = SuspenseWrapper(
  lazy(() => import("components/fee/FeeRefundForm"))
);
const FeeReceiptRefundableList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeReceiptRefundableList"))
);
const FeeRefundList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeRefundList"))
);
const FeeStructureScholarItemList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeStructureScholarItemList"))
);

const FeeImportForm = SuspenseWrapper(
  lazy(() => import("components/fee/FeeImportForm"))
);
const FeeImportList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeImportList"))
);
const FeeImportStatusList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeImportStatusList"))
);
const InvoiceImportForm = SuspenseWrapper(
  lazy(() => import("components/fee/FeeImportForm"))
);
const InvoiceImportList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeImportList"))
);
const InvoiceImportStatusList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeImportStatusList"))
);
const FeeScholarList = SuspenseWrapper(
  lazy(() => import("components/fee/reports/FeeScholarList"))
);
const FeeConcessionCategoryList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeConcessionCategoryList"))
);
const FeeConcessionCategoryForm = SuspenseWrapper(
  lazy(() => import("components/fee/FeeConcessionCategoryForm"))
);
const FeeScholarNewList = SuspenseWrapper(
  lazy(() => import("components/fee/reports/FeeScholarNewList"))
);
const ReceiptCancelList = SuspenseWrapper(
  lazy(() => import("components/fee/reports/ReceiptCancelList"))
);
const DayReport = SuspenseWrapper(
  lazy(() => import("components/fee/reports/DayReport"))
);
const ReceiptItemList = SuspenseWrapper(
  lazy(() => import("components/fee/reports/ReceiptItemList"))
);
const ReceiptFeeItemColumnWise = SuspenseWrapper(
  lazy(() => import("components/fee/reports/ReceiptFeeItemColumnWise"))
);
const GroupFeeList = SuspenseWrapper(
  lazy(() => import("components/fee/reports/GroupFeeList"))
);
const TermReportColumnWise = SuspenseWrapper(
  lazy(() => import("components/fee/reports/TermReportColumnWise"))
);
const FeeStatusClassWiseList = SuspenseWrapper(
  lazy(() => import("components/fee/reports/FeeStatusClassWiseList"))
);
const FeeStatusItemWiseList = SuspenseWrapper(
  lazy(() => import("components/fee/reports/FeeStatusItemWiseList"))
);
const FeeStatusTermWiseList = SuspenseWrapper(
  lazy(() => import("components/fee/reports/FeeStatusTermWiseList"))
);
const FeeConcessionReport = SuspenseWrapper(
  lazy(() => import("components/fee/reports/FeeConcessionReport"))
);
const ReceiptStatusReport = SuspenseWrapper(
  lazy(() => import("components/fee/reports/ReceiptStatusReport"))
);

const FeeOnlinePaymentGWList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeOnlinePaymentGWList"))
);
const FeeOnlinePaymentGWForm = SuspenseWrapper(
  lazy(() => import("components/fee/FeeOnlinePaymentGWForm"))
);
const FeeOnlinePGWBankForm = SuspenseWrapper(
  lazy(() => import("components/fee/FeeOnlinePGWBankForm"))
);
const FeeOnlinePGWBankList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeOnlinePGWBankList"))
);
const OutstandingList = SuspenseWrapper(
  lazy(() => import("components/fee/reports/OutstandingList"))
);

const FeeAlertSettingsList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeAlertSettingsList"))
);
const FeeAlertSettingsStep = SuspenseWrapper(
  lazy(() => import("components/fee/FeeAlertSettingsStep"))
);
const FeeOnlinePaymentList = SuspenseWrapper(
  lazy(() => import("components/fee/reports/OnlinePaymentList"))
);

const FeeOnlinePGWResponse = SuspenseWrapper(
  lazy(() => import("components/fee/FeeOnlinePGWResponse"))
);

const FeeOnlineCollectionStatusForm = SuspenseWrapper(
  lazy(() => import("components/fee/FeeOnlineCollectionStatusForm"))
);

const FeeTotalsummary = SuspenseWrapper(
  lazy(() => import("components/fee/reports/FeeTotalsummary"))
);

const FeeOnlineSettleList = SuspenseWrapper(
  lazy(() => import("components/fee/reports/FeeOnlineSettleList"))
);

const FeeOnlineSettleDetList = SuspenseWrapper(
  lazy(() => import("components/fee/reports/FeeOnlineSettleDetList"))
);

const FeeWaiverCategoryList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeWaiverCategoryList"))
);
const FeeWaiverCategoryForm = SuspenseWrapper(
  lazy(() => import("components/fee/FeeWaiverCategoryForm"))
);

const FeeWaiverReport = SuspenseWrapper(
  lazy(() => import("components/fee/reports/FeeWaiverReport"))
);

const LateFeeList = SuspenseWrapper(
  lazy(() => import("components/fee/LateFeeList"))
);
const LateFeeStep = SuspenseWrapper(
  lazy(() => import("components/fee/LateFeeStep"))
);

const LateFeeView = SuspenseWrapper(
  lazy(() => import("components/fee/LateFeeView"))
);

const LateFeeScholarList = SuspenseWrapper(
  lazy(() => import("components/fee/LateFeeScholarList"))
);


const ReceiptImportForm = SuspenseWrapper(
  lazy(() => import("components/fee/FeeImportForm"))
);
const ReceiptImportList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeImportList"))
);
const ReceiptImportStatusList = SuspenseWrapper(
  lazy(() => import("components/fee/FeeImportStatusList"))
);
const FeeBankSplitReportList = SuspenseWrapper(
  lazy(() => import("components/fee/reports/FeeBankSplitReportList"))
);
const FeeSettlementOverView = SuspenseWrapper(
  lazy(() => import("components/fee/reports/FeeSettlementOverView"))
);
const GroupFeeCollection = SuspenseWrapper(
  lazy(() => import("components/fee/reports/GroupFeeCollection"))
);
const FeeScholarFeeIndividual = SuspenseWrapper(
  lazy(() => import("components/fee/reports/FeeScholarFeeIndividual"))
);
const FeeStructureCorrStep = SuspenseWrapper(
  lazy(() => import("components/fee/Correction/FeeStructureCorrStep"))
);

const TeacherOutstandingList = SuspenseWrapper(
  lazy(() => import("components/fee/reports/TeacherOutstandingList"))
);
//TeacherOutstandingList

const FeeRoutes = {
  path: "fee",
  element: <Outlet />,
  children: [     
    {
      path: "reports",
      element: <Outlet />,
      children: [
        {
          path: "collection",
          element: <FeeCollectionList />,
        },
        {
          path: "receipt",
          element: <FeeReceiptList />,
        },
        {
          path: "outstanding",
          element: <OutstandingList />,
        },
        {
          path: "scholar",
          element: <FeeScholarList />,
        },
        {
          path: "scholar-new",
          element: <FeeScholarNewList />,
        },
        {
          path: "receipt-cancel",
          element: <ReceiptCancelList />,
        },
        {
          path: "day-rpt",
          element: <DayReport />,
        },
        {
          path: "receipt-item",
          element: <ReceiptItemList />,
        },
        {
          path: "receipt-itemcol-wise",
          element: <ReceiptFeeItemColumnWise />,
        },
        {
          path: "group-fee",
          element: <GroupFeeList />,
        },
        {
          path: "group-fee-collection",
          element: <GroupFeeCollection />,
        },
        {
          path: "term-report",
          element: <TermReportColumnWise />,
        },
        {
          path: "concession-report",
          element: <FeeConcessionReport />,
        },
        {
          path: "onlinepayhistory",
          element: <FeeOnlinePaymentList />,
        },
        {
          path: "feetotalsummary",
          element: <FeeTotalsummary />,
        },
        {
          path: "olsettle",
          element: <FeeOnlineSettleList />,
        },
        {
          path: "olsettle-det/:hash",
          element: <FeeOnlineSettleDetList />,
        },
        {
          path: "feeWaiver-report",
          element: <FeeWaiverReport />,
        },
        {
          path: "olsettlereport",
          element: <FeeBankSplitReportList />,
        },
        {
          path: "feesettlementoverview",
          element: <FeeSettlementOverView />,
        },
        {
          path: "scholar-individual",
          element: <FeeScholarFeeIndividual />,
        },
        {
          path: "teacher-outstanding",
          element: <TeacherOutstandingList />,
        },
      ],
    },
    {
      path: "receipt-head",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <ReceiptHeadList />,
        },
        {
          path: "add",
          element: <ReceiptHeadForm />,
        },
      ],
    },
    
    {
      path: "account",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <AccountList />,
        },
        {
          path: "add",
          element: <AccountForm />,
        },
      ],
    },
    {
      path: "payment-mode",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <PaymentModeList />,
        },
        {
          path: "add",
          element: <PaymentModeForm />,
        },
      ],
    },
    {
      path: "fee-terms",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <FeeTermsList />,
        },
        {
          path: "add",
          element: <FeeTermsForm />,
        },
      ],
    },
    {
      path: "fee-category",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <FeeCategoryList />,
        },
        {
          path: "add",
          element: <FeeCategoryForm />,
        },
      ],
    },
    {
      path: "fee-item",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <FeeItemList />,
        },
        {
          path: "add",
          element: <FeeItemForm />,
        },
      ],
    },
    {
      path: "fee-bank",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <FeeBankList />,
        },
        {
          path: "add",
          element: <FeeBankForm />,
        },
      ],
    },
    {
      path: "fee-structure",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <FeeStructureList />,
        },
        {
          path: "add",
          element: <FeeStructureStep />,
        },
        {
          path: "edit/:hash",
          element: <FeeStructureStep />,
        },
        {
          path: "view/:hash",
          element: <FeeStructureView />,
        },
        {
          path: "scholarlist/:hash",
          element: <FeeStructureScholarList />,
        },
        {
          path: "scholar-itemlist/:hash",
          element: <FeeStructureScholarItemList />,
        },
        {
          path: "corr-edit/:hash",
          element: <FeeStructureCorrStep />,
        },
      ],
    },
    {
      path: "collection",
      element: <FeeCollectionForm />,
    },
    {
      path: "fee-concession",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <FeeConcessionList />,
        },
        {
          path: "add",
          element: <FeeConcessionStep />,
        },
        {
          path: "edit/:hash",
          element: <FeeConcessionStep />,
        },
        {
          path: "view/:hash",
          element: <FeeConcessionView />,
        },
        {
          path: "scholarlist/:hash",
          element: <FeeConcessionScholarList />,
        },
      ],
    },
    {
      path: "receipt-cancel",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <FeeReceiptCancelList />,
        },
        {
          path: "add",
          element: <FeeReceiptCancelForm />,
        },
      ],
    },
    {
      path: "cancel-reason",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <FRCancelReasonList />,
        },
        {
          path: "add",
          element: <FRCancelReasonForm />,
        },
      ],
    },
    {
      path: "fee-refund",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <FeeRefundList />,
        },
        {
          path: "add",
          element: <FeeRefundForm />,
        },
      ],
    },
    {
      path: "receipt-refundable",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <FeeReceiptRefundableList />,
        },
      ],
    },
    {
      path: "online_payment_gw",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <FeeOnlinePaymentGWList />,
        },
        {
          path: "add",
          element: <FeeOnlinePaymentGWForm />,
        },
      ],
    },
    {
      path: "online_pgw_bank",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <FeeOnlinePGWBankList />,
        },
        {
          path: "add",
          element: <FeeOnlinePGWBankForm />,
        },
      ],
    },
    {
      path: "alert-settings",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <FeeAlertSettingsList />,
        },
        {
          path: "add",
          element: <FeeAlertSettingsStep />,
        },
        {
          path: "edit/:hash",
          element: <FeeAlertSettingsStep />,
        },
      ],
    },
    {
      path: "import-list",
      element: <FeeImportList mode="F" />,
    },
    {
      path: "import",
      element: <FeeImportForm mode="F" />,
    },
    {
      path: "import-status/:hash",
      element: <FeeImportStatusList mode="F" />,
    },
    {
      path: "invoice-import-list",
      element: <InvoiceImportList mode="I" />,
    },
    {
      path: "invoice-import",
      element: <InvoiceImportForm mode="I" />,
    },
    {
      path: "invoice-import-status/:hash",
      element: <InvoiceImportStatusList mode="I" />,
    },
    {
      path: "fee-concession-category",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <FeeConcessionCategoryList />,
        },
        {
          path: "add",
          element: <FeeConcessionCategoryForm />,
        },
      ],
    },
    {
      path: "status",
      element: <Outlet />,
      children: [
        {
          path: "class",
          element: <FeeStatusClassWiseList />,
        },
        {
          path: "item",
          element: <FeeStatusItemWiseList />,
        },
        {
          path: "term",
          element: <FeeStatusTermWiseList />,
        },
        {
          path: "list",
          element: <ReceiptStatusReport />,
        },
      ],
    },      
    {
      path: "online-pgw",
      element: <Outlet />,
      children: [
        {
          path: "response",
          element: <FeeOnlinePGWResponse />,
        },
        {
          path: "status/:hash",
          element: <FeeOnlineCollectionStatusForm />,
        },       
      ],
    },
    {
      path: "fee-waiver",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <FeeWaiverCategoryList />,
        },
        {
          path: "add",
          element: <FeeWaiverCategoryForm />,
        },
      ],
    },
    {
      path: "latefee",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <LateFeeList />,
        },
        {
          path: "add",
          element: <LateFeeStep />,
        },
        {
          path: "edit/:hash",
          element: <LateFeeStep />,
        },
        {
          path: "view/:hash",
          element: <LateFeeView />,
        },
        {
          path: "scholarlist/:hash",
          element: <LateFeeScholarList />,
        },
        {
          path: "scholar-itemlist/:hash",
          element: <FeeStructureScholarItemList />,
        },
      ],    
    },
    {
      path: "receipt-import-list",
      element: <ReceiptImportList mode="R" />,
    },
    {
      path: "receipt-import",
      element: <ReceiptImportForm mode="R" />,
    },
    {
      path: "receipt-import-status/:hash",
      element: <ReceiptImportStatusList mode="R" />,
    },    

  ],
};

export default FeeRoutes;
